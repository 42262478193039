<template>
  <div class="">
    <!--本页切换列表-->
    <div>
      <div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
        <div class="tableConTable">
          <div class="tableConTop">
            <el-row>
              <el-col :span="5" class="tableConTopLeft">
                <h3>
                  <span class="tct_tit">
									{{$t('i18nn_0f864f84a5e810d6')}}
									</span>
									<span v-if="!isEdit">(仅预览)</span>
                  <el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
                  	<el-button type="warning" circle icon="el-icon-download" size="mini"
                  		@click="exportExcel()"></el-button>
                  </el-tooltip>
                </h3>
              </el-col>
              <el-col :span="19" class="tableConTopRig">
                <!-- <el-button  @click="updatePartnerDataAction($event,$t('4e9fc68608c60999'))" type="danger" size="small" icon="el-icon-close">{{$t('i18nn_4f62f8d07bb4054f')}}</el-button> -->
                <!-- <span v-if="!isSel"> -->
                <!-- <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null, $t('i18nn_831ab31568a78285'))">{{$t('i18nn_831ab31568a78285')}}</el-button> -->
                <!-- </span> -->
                <!-- <el-button @click="completeAction($event,null)" type="danger" size="small" icon="el-icon-finished">{{$t('25e03120b83ee4ed')}}</el-button> -->
                <!--右边容器-->
                <!-- <slot name="topRight"></slot> -->
                <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
              </el-col>
            </el-row>
          </div>

          <div>
            <div class="filterCon" style="">
              <!-- <el-row type="flex" justify="start"> -->
                <!-- <el-col :span="24"> -->
                  <ul class="filterConList">
                    <!-- <li>
                      <span>{{$t('6cdece641436d7ab')}}</span>
                      <el-select filterable clearable v-model="filterData.plStatus" :placeholder="$t('2ad108ab2c560530')" size="small" style="width: 100px;" @change="initData()">
                        <el-option v-for="item in selectOption.plStatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                      </el-select>
                    </li> -->
                    <li>
                      <!-- <span>userId</span> -->
                      <!-- <span>
												{{filterData.userName}}
											</span>
											<el-button type="success" size="small" icon="el-icon-search" @click="openWhCustomerSelData($event)">{{$t('i18nn_b83597729ab29694')}}</el-button> -->
											
											<span>{{ $t('hytxs0000060') }}</span>
											<cusSelFuzzy @changeData="changCus"></cusSelFuzzy>
											<!-- <el-input
											  type="text"
											  v-model="filterData.userId"
											  size="small"
											  clearable
											  @keyup.enter.native="initData()"
											  maxlength="50"
											  :placeholder="$t('hytxs0000001')"
											  style="width: 180px;"
											/> -->
											
											<!-- <el-input disabled :placeholder="$t('hytxs0000001')" v-model="filterData.userId" clearable @keyup.enter.native="initData()" class="">
											   
													<el-button type="success" slot="prepend" size="small" icon="el-icon-search" @click="openWhCustomerSelData($event)">{{$t('i18nn_b83597729ab29694')}}</el-button>
													
											    <el-button slot="append" icon="el-icon-search" @click="initData()">{{$t('i18nn_cefda9657773f55f')}}</el-button>
											  </el-input> -->
												
										</li>
										<li v-if="tableData.length<=0">
											<el-button icon="el-icon-s-promotion" size="small" type="primary" @click="createPrepaidAccount()">{{$t('i18nn_a9320232a40b10dc')}}</el-button>
										</li>
                    <!-- <li>
                      <span>{{$t('i18nn_49504f74aad443ce')}}</span>
                      <el-input
                        type="text"
                        v-model="filterData.accountName"
                        size="small"
                        clearable
                        @keyup.enter.native="initData()"
                        maxlength="50"
                        :placeholder="$t('hytxs0000001')"
                        style="width: 180px;"
                      />
                    </li> -->
										<!-- <li><el-button icon="el-icon-search" size="small" type="primary" @click="serAll()">{{$t('i18nn_10d660564497918d')}}</el-button></li> -->
										
                    <!-- <li><el-button icon="el-icon-search" size="small" type="primary" @click="initData()">{{$t('1e7246dd6ccc5539')}}</el-button></li> -->
                  </ul>
                  <!-- <el-select filterable clearable size="small" v-model="queryParamObj.feeType" :placeholder="$t('5a9aefbc03c778f7')" style="width: 150px;">
                    <el-option v-for="item in selectOption.matching_fee" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ item.companyName }}</span>
				<span class="sel_option_code" style="">{{ item.cusNo }}</span>
			</div></el-option>
                  </el-select> -->
                <!-- </el-col> -->
                <!-- <el-col :span="5">
                  <span>{{$t('i18nn_b9cf6fa157d27973')}}</span>
                  <el-select filterable clearable size="small" v-model="queryParamObj.goodsType" :placeholder="$t('i18nn_b9cf6fa157d27973')" style="width: 120px;">
                    <el-option v-for="item in selectOption.goods_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
                    </el-option>
                  </el-select>
                </el-col> -->
                <!-- <el-col :span="2"><el-button type="primary" @click="serPageData" size="small">{{$t('9eefb7335988c499')}}</el-button></el-col> -->
                <!-- <el-col :span="6" class="tableConTopRig">

                </el-col> -->
              <!-- </el-row> -->
            </div>
          </div>

          <el-table
            id="out-table2"
            ref="multipleTable"
            :data="tableData"
            stripe
            :border="true"
            :height="$store.state.tableMaxHeight2"
            @row-click="handleCurrentChange"
            @selection-change="handleSelectionChange"
            style="width: 100%"
            size="small"
          >
            <el-table-column type="index" :index="$Utils.tableIndex(pagination)"  fixed="left" width="50" align="center" :label="$t('7b1c2b1adc920d9c')"></el-table-column>
            <!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->

            <!-- <el-table-column :label="$t('i18nn_8bdf7efaeff69d77')"> -->
            <!-- <el-table-column prop="plStatusName" :label="$t('6cdece641436d7ab')"></el-table-column> -->

            <!-- <el-table-column prop="opNo" :label="$t('i18nn_a51a59d19387d567')"></el-table-column> -->
            <!-- <el-table-column prop="opNo" :label="$t('i18nn_a51a59d19387d567')">
              <template slot-scope="scope">
                  <router-link :to="{name:'WhFinanceDetList',query:{opNo:scope.row.opNo}}">{{scope.row.opNo}}</router-link>
             </template>
            </el-table-column> -->
            <el-table-column prop="status" :label="$t('6cdece641436d7ab')">
              <template slot-scope="scope">
                <el-tag type="primary" v-if="'1'==scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
                <el-tag type="warning" v-else-if="'2'==scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
                <el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
              </template>
            </el-table-column>
						
						<!-- <el-table-column prop="cusName" :label="$t('hytxs0000060')" show-overflow-tooltip>
						</el-table-column> -->
						
            <el-table-column prop="accountName" :label="$t('i18nn_169a0780408f1cce')"></el-table-column>

            <!-- <el-table-column prop="accountType" :label="$t('i18nn_4a0cacddeb2e38b1')"></el-table-column> -->

            <el-table-column prop="useAmt" :label="$t('i18nn_a06e84801d6582be')"></el-table-column>

            <el-table-column prop="userId" :label="'UserId'"></el-table-column>

            <!-- <el-table-column prop="showAmt" :label="'showAmt'"></el-table-column> -->

            <!-- <el-table-column prop="plCount" :label="$t('i18nn_06c0936eb86ae31b')"></el-table-column> -->

            <!-- <el-table-column prop="remark" :label="$t('15b3627faddccb1d')"></el-table-column> -->

            <!-- <el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')"></el-table-column> -->

            <!-- <el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')"></el-table-column> -->

            <el-table-column :label="$t('93f5ca01b006206c')" width="220px" fixed="right" v-if="isEdit">
              <template slot-scope="scope">
                <el-button @click="openEdit($event,scope.row)" type="warning" size="mini" icon="el-icon-edit-outline">{{$t('6267f3aedf895209')}}</el-button>
								
								<!-- <el-button @click="payAction($event,scope.row)" type="primary" size="mini" icon="el-icon-bank-card">{{$t('i18nn_831ab31568a78285')}}</el-button> -->
								
                <!-- <el-button type="danger" size="mini" icon="el-icon-minus" @click="delAction($event, scope.row)" v-if="'2'!=scope.row.opStatus">{{$t('e33c9b93c36fd250')}}</el-button> -->

                <!-- <el-button @click="completeAction($event, scope.row)" type="success" size="mini" icon="el-icon-finished" v-if="'2'!=scope.row.opStatus">{{$t('25e03120b83ee4ed')}}</el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="tableConPagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
      </div>
    </div>
    <!-- 修改弹窗 -->
    <!--本页切换，新增、修改、查看等-->
    <!-- <hyPageShow :visible="dialogFormVisible" @updateVisible="updateVisible" :title="$t('i18nn_0f864f84a5e810d6')" :subtitle="dialogFormVisibleMsg"> -->
    <el-dialog :title="dialogFormVisibleMsg" :visible.sync="dialogFormVisible" custom-class="myFullDialog2">
      <div class="detEditOpenCon">
        <el-form ref="form" :rules="formRules" :model="form" label-width="100px" v-loading="loading" :element-loading-text="$t('44bd089554515c4d')">
          <!-- <h3 class="titSty1">{{$t('8ed435ced73dc9bf')}}</h3> -->
          <!-- <el-form-item label="标题：" prop="title"><el-input type="text" v-model="form.title" :placeholder="$t('5a9aefbc03c778f7')" style="width: 300px;"></el-input></el-form-item> -->


          <el-form-item :label="$t('i18nn_c38333badc226309')" prop="">
            <!-- <el-input type="text" v-model="form.cusName" :placeholder="$t('5a9aefbc03c778f7')"></el-input> -->
            <!-- <div> -->
              <!-- <el-button type="success" size="mini" icon="el-icon-edit" @click="openWhCustomerSelData($event)">{{$t('i18nn_b83597729ab29694')}}</el-button> -->
              <!-- <span>{{form.cusName}}</span>
              <span v-if="form.cusNo">(NO:{{form.cusNo}})</span> -->
            <!-- </div> -->
            <!-- <div v-if="form.cusName"> -->
              <!-- <span>{{$t('hytxs0000060')}}</span>： --><el-tag>{{form.accountName}}</el-tag>
            <!-- </div> -->
            <!-- <div v-if="form.userId"> -->
              <!-- <span>{{$t('209fdd8f47e20fbf')}}</span>： --><el-tag type="info">{{form.userId}}</el-tag>
            <!-- </div> -->
          </el-form-item>

          <el-form-item :label="$t('i18nn_a06e84801d6582be')" prop="">
						<!-- <el-input type="text" v-model="form.describe" :placeholder="$t('5a9aefbc03c778f7')" style="width: 300px;"></el-input> -->
						<!-- <el-input-number v-model="form.useAmt" :label="$t('i18nn_01571203859d6b8c')"></el-input-number> -->
						{{form.oldUseAmt}}
					</el-form-item>
         
					<el-form-item :label="$t('i18nn_4926f0dc61317402')" prop="">
						<!-- <el-input type="text" v-model="form.describe" :placeholder="$t('5a9aefbc03c778f7')" style="width: 300px;"></el-input> -->
						<el-input-number v-model="form.useAmt" :label="$t('i18nn_01571203859d6b8c')"></el-input-number>
						(<span>{{$t('i18nn_93e87666aa87fa25')}}</span>)
					</el-form-item>
					
          <!-- <h3 class="titSty1">{{$t('97e49d00bcc4c17c')}}</h3> -->
          <el-form-item :label="$t('15b3627faddccb1d')">
            <el-input type="textarea" :placeholder="$t('i18nn_966f3d0952773d84')" v-model="form.remark"  :maxlength="1000" show-word-limit></el-input>
          </el-form-item>
        </el-form>
        <!-- <div>
          <el-button type="primary" style="width: 220px;" @click="submitForm('form')">{{$t('i18nn_d552aa9f1c14363f')}}</el-button>
        </div> -->
      </div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm('form')">{{$t('i18nn_d552aa9f1c14363f')}}</el-button>
				<el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('4e9fc68608c60999') }}</el-button>
			</div>
      </el-dialog>
    <!-- </hyPageShow> -->
    <!-- 注销弹窗 -->
    <!-- <el-dialog :title="$t('i18nn_dad95a93a594366e')" :close-on-click-modal="false" :visible.sync="dialogFormInvalidDate">
      <el-form ref="invalidDateForm" :model="invalidDateForm" :rules="formRules" label-width="100px" v-loading="loading" :element-loading-text="$t('44bd089554515c4d')">

        <div style="line-height: 24px;">
          <span>{{$t('i18nn_ba0732214679e13b')}}</span>
          <span>{{invalidDateForm.confName}}</span>
        </div>
        <div style="line-height: 24px;">
          <span>{{$t('i18nn_2345771b0cea8487')}}</span>
          <span>{{invalidDateForm.effectiveDate}}</span>
        </div>
        <el-form-item label="注销日期：" prop="invalidDate">
          <el-date-picker size="small" v-model="invalidDateForm.invalidDate" value-format="yyyy-MM-dd" align="right" type="date" :placeholder="$t('68efabd029c860a9')" :picker-options="pickerOptions1" style="width: 150px;">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="dialogFormInvalidDate = false">{{$t('4e9fc68608c60999')}}</el-button>
        <el-button type="primary" @click="submitForm('invalidDateForm','2')">{{$t('i18nn_dad95a93a594366e')}}</el-button>
      </div>
    </el-dialog> -->
    <!-- 详情弹窗 -->
    <!-- <el-dialog :title="$t('i18nn_3d76412ed771dbe2')" :close-on-click-modal="false"  :visible.sync="dialogDetVisible" :top="'10px'">
      <div v-loading="loading_det">
        <div class="tableCon" v-loading="loading_det" :element-loading-text="$t('47df8be257c59dde')">
          <div class="tableConTable">
            <el-table :height="$store.state.tableMaxHeight" border :data="detData" style="width: 100%">
              <el-table-column prop="settlementPartyName" min-width="100px" :label="$t('i18nn_2afd7f6f1166341d')">

              </el-table-column>
              <el-table-column prop="benefitedPartyName" min-width="100px" :label="$t('i18nn_442a9ac138407a28')">

              </el-table-column>
              <el-table-column prop="partnerTypeName" min-width="100px" :label="$t('i18nn_bb5ac0c49150f3b0')">

              </el-table-column>
              <el-table-column prop="distributionWaysName" min-width="100px" :label="$t('i18nn_f7e0da8748d5c821')">

              </el-table-column>
              <el-table-column prop="distributionValue" min-width="100px" :label="$t('i18nn_ed085b86f07e3e99')">

              </el-table-column>

            </el-table>
          </div>
        </div>
      </div>
    </el-dialog> -->
    <!--选择入库数据-->
    <!-- <el-dialog :title="$t('7fb6ada66a1fccca')" append-to-body :close-on-click-modal="false" :visible.sync="dialogSelVisible" width="1200px" top="0">
      <div style="">
        <WhCustomer :isSel="true" @selectRow="selWhCustomerData"></WhCustomer>
      </div>
      <div slot="footer" class="dialog-footer"><el-button type="primary" plain @click="dialogSelVisible = false">{{$t('4e9fc68608c60999')}}</el-button></div>
    </el-dialog> -->
		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData"
			:pagination="pagination" :excelName="excelName" :excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhAccountPageList" :expHttpFilter="pageFilterData()"></whExcelCustom>
			
  </div>
</template>
<script>
// import WhCustomer from '@/components/WarehouseCenter/WhCustomer.vue';
import whExcelCustom from '@/components/WarehouseCenter2/components/whExcelCustom.vue';

import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
export default {
	props: {
		isEdit: {
			default: function() {
			  return false;
			},
			type: Boolean
		},
		// row: {}
	},
  components: {
    // WhCustomer
		cusSelFuzzy,
		whExcelCustom
  },
  data() {
    return {
			//excel导出
			excelOpenTime: '',
			excelHead: [],
			expExcelData: [],
			excelName: "",
			excelOption: {},
      //打开入库选择框
      // dialogSelVisible: false,

      // openRowData: {},
      // openRowIndex: {},

      dialogFormVisible: false,
      dialogFormVisibleMsg: '',
      // dialogFormInvalidDate: false,
      // currentSel: {},
      // queryParamObj: {
      //   feeType: "",
      //   goodsType: ""
      // },
      // selectOption: {
      //   matching_fee: [],
      //   goods_type: [],
      //   stl_enterprise: [],
      //   bene_enterprise: [],
      //   cal_model: [],
      //   cal_param: [],
      //   val_model: [],
      //   calc_unit: []

      // },
      // initLinkageData: {},
      //表格显示
      // tableHeader: [
      //   {
      //     prop: 'plStatusName',
      //     label: this.$t('6cdece641436d7ab')
      //   },
      //   {
      //     prop: 'plNo',
      //     label: this.$t('i18nn_0d6140ad7af4ed36')
      //   },
      //   {
      //     prop: 'plName',
      //     label: this.$t('i18nn_862bfc135547f78e')
      //   },
      //   {
      //     prop: 'plCount',
      //     label: this.$t('i18nn_0bd7d983fe70ea56')
      //   },
      //   {
      //     prop: 'updateTime',
      //     label: this.$t('i18nn_fdc34fd7121f9c48')
      //   },
      //   {
      //     prop: 'createTime',
      //     label: this.$t('i18nn_726c51702f70c486')
      //   }

      // ],
      selectOption: {
        plStatusList: [
          {
            value: '',
            label: this.$t('16853bda54120bf1')
          },
          {
            value: '1',
            label: this.$t('i18nn_9cee201b82dbc9cb')
          },
          {
            value: '2',
            label: this.$t('1ff1fd8ddb3e0e8d')
          }
          // {
          //   value: '3',
          //   label: this.$t('i18nn_821531c6e29bd318')
          // },
          // {
          //   value: '4',
          //   label: this.$t('i18nn_cadfbfa511d00eb1')
          // },
          // {
          //   value: '5',
          //   label: this.$t('i18nn_4b95be19dadf6f0a')
          // },
          // {
          //   value: '6',
          //   label: this.$t('i18nn_5599155c3578c611')
          // },
        ]
      },
      //生效日期，日期选项
      // pickerOptions2: {
      //   disabledDate(time) {
      //     return time.getTime() < Date.now();
      //   },
      // },
      // //日期选项
      // pickerOptions1: {
      //   disabledDate(time) {
      //     return time.getTime() < Date.now();
      //   },
      // },
      //表格数据
      //loading,表格数据
      loading: false,
		loading_load: false,
      //表格数据
      tableData: [],
      //分页数据
      pagination: this.$Utils.defaultPagination(),
      //详情数据
      loading_det: false,
      // detData: [],
      // dialogDetVisible: false,

      // {
      //     "id":"数据ID",
      //     "title":this.$t('7431e76029678ec7'),
      //     "opNo":this.$t('i18nn_a51a59d19387d567'),
      //     "describe":this.$t('i18nn_ab6ea90b9164b20a'),
      //     "finRecords":[
      //         {
      //             "finName":this.$t('i18nn_a4eca6ff2588d791'),
      //             "finAmt":"0",
      //             "id":"数据ID"
      //         }
      //     ]
      // },

      form: {
        id: null,

        userId: '', //标题',
        useAmt: '', //this.$t('i18nn_ab6ea90b9164b20a'),
        remark:'',
        // finRecords: [
        //   {
        //     finName: '', //this.$t('i18nn_a4eca6ff2588d791'),
        //     finAmt: '' //"0",
        //     // "id":"",//"数据ID"
        //   }
        // ]

        // "confName": "",
        // "confId": "", //新增时 为空  编辑时必传
        // "feeType": "", //费用类型

        // "divConfList": [{
        //   "benefitedParty": "", //受益方
        //   "partnerType": "", //合伙人类型
        //   "divWays": "", //分配方式
        //   "divValue": "" //分配值
        // }]
        // effectiveDate:"",//生效时间
      },
      // invalidDateForm: {
      //   "confId": null,
      //   "invalidDate": "", //失效日期
      //   effectiveDate: "", //生效时间
      //   confName: "", //仅做显示
      // },
      formRules: {
        userId: [{ required: true, message: this.$t('5a9aefbc03c778f7'), trigger: 'blur' }],
				useAmt: [{ required: true, message: this.$t('5a9aefbc03c778f7'), trigger: 'change' }]
      },
      //查询，排序方式
      filterData: {
        orderBy: 'id_', //排序字段
        sortAsc: 'desc', //desc降序，asc升序

        // plStatus:'',
        userId: '',
				userName:''
        // accountName: ''
      }
    };
  },
	activated(){
		this.initData();
	},
  //创建时
  created() {
    
  },
  //编译挂载前
  mounted() {
    // console.log("mounted");
  },
  methods: {
    initData() {
      this.pagination.current_page = 1;
      this.currentSel = {};
      this.multipleSelection = [];
      this.getPageData();
      // this.getDicData();
    },
		//导出 excel
		exportExcel() {
		
			let columns = [{
					title: this.$t('6cdece641436d7ab'),
					key: 'statusName'
				},
				{
					title: this.$t('i18nn_169a0780408f1cce'),
					key: 'accountName'
				},
				{
					title: this.$t('i18nn_a06e84801d6582be'),
					key: 'useAmt'
				},
				{
					title: 'userId',
					key: 'userId'
				},
			];
			// let Data = this.tableData;
		
			this.expExcelData = this.tableData;
			this.excelHead = columns;
			this.excelName = 'WhAccountListCom';
			this.excelOption = {
				height: 20
			};
			this.excelOpenTime = new Date().getTime();
		
			// excelUtilsNew.exportExcel(columns, Data, 'WhAccountRec',{height:20});
		},
		// serAll(){
		// 	this.filterData.userId = "";
		// 	this.filterData.userName = "";
		// 	this.initData();
		// },
    //类目选择
    // selChangeData_1(v) {
    //   console.log(v);
    //   this.form.subjectId = v.data_1_Id;
    // },
    // selChangeData_2(v) {
    //   console.log(v);
    //   this.form.subjectId = v.data_2_Id;
    // },
    // selChangeData_3(v) {
    //   console.log(v);
    //   this.form.subjectId = v.data_3_Id;
    // },
    //直接打开页面
    // toPageName(name) {
    //   this.$router.push({ name: name });
    // },
    //打开新增编辑
    // toPageNameParm(name, formParm) {
    //   if (null === formParm) {
    //     this.$router.push({ name: name });
    //   } else {
    //     this.$router.push({ name: name, query: { id: formParm.id } });
    //   }
    // },
    // toDet(row) {
    //   this.$router.push({ name: 'WhPickingDetList', query: { plNo: row.plNo } });
    //   let title = '';
    //   let key = '';
    //   this.$store.dispatch('add_history_Tab_Data', {
    //     title: title,
    //     routeName: key,
    //     query: {}
    //   });
    // },
    //打开选择数据
   //  openWhCustomerSelData(event) {
   //    event.stopPropagation();

   //    this.dialogSelVisible = true;
   //    // this.openRowData = row;
   //    // this.openRowIndex = index;
   //    // this.$nextTick(() => {
   //    //   this.$refs.hyUpLoadImg1.openFile();
   //    // });
   //  },
   //  //选择数据后回调
   //  selWhCustomerData(selRow) {
   //    this.dialogSelVisible = false;

   //    // let row = this.openRowData;
   //    // row.putWhNo = selRow.putWhNo;
   //    // row.plPlaceNo = selRow.placeNo;
   //    // row.goodsSku = selRow.goodsSku;
   //    // row.goodsImg = selRow.goodsImg;
   //    // row.goodsName = selRow.goodsName;
   //    // this.$set(this.tableData, this.openRowIndex, row);

   //    // this.form.cusName = selRow.companyName;

   //    // this.form.userId = selRow.userId;
			
			// this.filterData.userId= selRow.userId;
			// this.filterData.userName = selRow.companyName;
			// this.initData();
   //    // this.openRowData = row;
   //    // this.openRowIndex = index;
   //    // this.$nextTick(() => {
   //    //   this.$refs.hyUpLoadImg1.openFile();
   //    // });
   //  },
		changCus(data) {
			console.log('changCus', data);
			// this.dialogSelVisible = false;
			
			// let row = this.openRowData;
			// row.putWhNo = selRow.putWhNo;
			// row.plPlaceNo = selRow.placeNo;
			// row.goodsSku = selRow.goodsSku;
			// row.goodsImg = selRow.goodsImg;
			// row.goodsName = selRow.goodsName;
			// this.$set(this.tableData, this.openRowIndex, row);
			
			// this.form.cusName = selRow.companyName;
			
			// this.form.userId = selRow.userId;
			
			this.filterData.userId= data.userId;
			this.filterData.userName = data.companyName;
			this.initData();
		},
		//生成预付账户
		createPrepaidAccount(){
			if(this.filterData.userId){
				this.createPrepaidAccountData(this.filterData.userId);
			} else {
				this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
			}
		},
		createPrepaidAccountData(userId){
			// let formData = Object.assign({}, this.form);
			// formData.id = null;
			this.postData(this.$urlConfig.WhAccountAdd+'/'+userId, {}, () => {
			  // this.dialogFormVisible = false;
			  this.getPageData();
			  // this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
			  //   type: 'success',
			  //   
			  // });
			  this.$message.success(this.$t('i18nn_f7549b7e760a39cc'));
			});
		},
    //状态
    // formatterEnable(row, column){
    //   if('1'===row.enable){
    //     return this.$t('i18nn_889b3ef0590d9f54');
    //   } else if('0'===row.enable){
    //     return this.$t('4e9fc68608c60999');
    //   } else {
    //     return row.enable;
    //   }
    // },
    //查询数据
    // serPageData() {
    //   this.pagination.current_page = 1;
    //   this.getPageData();
    // },
    // formatterDate(row, column) {
    //   if (row.invalidDate == null) {
    //     return '';
    //   }
    //   return row.invalidDate.substring(0, 10);
    // },
		//分页的筛选项数据
		pageFilterData() {
			// let startTime = '';
			// let endTime = '';
			// if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
			// 	startTime = this.filterData.daterange[0];
			// 	endTime = this.filterData.daterange[1];
			// } else {
			// 	// this.$message.warning(this.$t('i18nn_cffd7c9783c11047'));
			// }
		
			return {
				// plStatus: this.filterData.plStatus? this.filterData.plStatus:null,
				userId: this.filterData.userId ? this.filterData.userId : null,
			};
		},
		
    //请求分页数据
    getPageData() {
      // let _this = this;
			let filterData = Object.assign({
				offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
				limit: this.pagination.page_size, //当前页显示数目
			}, this.pageFilterData())
			
      this.loading_load = true;
      this.$http
        .put(this.$urlConfig.WhAccountPageList, filterData)
        .then(({ data }) => {
          
          this.loading_load = false;
          if (200 == data.code) {
            //表格显示数据
            this.tableData = data.rows;
            //当前数据总条数
            this.pagination.total = parseInt(data.total);
            //当前页数
            // this.pagination.current_page = parseInt(data.current);
            //当前页条数
            // this.pagination.page_size = parseInt(data.size);
            // this.$message.success('商家账单列表，请求成功');
          } else {
            this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
          }
        })
        .catch(error => {
          console.log(error);
          this.$message.error(this.$t('hytxs0000030'));
          this.loading_load = false;
        });
    },
    //打开二级分配弹窗
    // openDetModal(row) {
    //   this.dialogDetVisible = true;
    //   this.detData = [];
    //   this.getDetData(row.id);
    // },
    // getDetListData(opNo) {
    //   this.loading_det = true;
    //   this.$http.put(this.$urlConfig.WhFinanceRecPageList, {
    //       offset: 0,
    //       limit: 100,
    //       "opNo": opNo
    //     })
    //     .then(({ data }) => {
    //       this.loading_det = false;
    //       if (200 == data.code) {
    //         //表格显示数据
    //         // this.detData = data.rows;
    //         this.form.finRecords = data.rows;
    //       } else {
    //         this.$message.warning(data.msg ? data.msg : this.$t('i18nn_9c0ace2b5d32f74c'));
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.$message.error('请求数据有误！');
    //       this.loading_det = false;
    //     });
    // },
    //新增
    // addLe2() {
    //   this.form.finRecords.push({
    //     finName: '',
    //     finAmt: '0',
    //     remark:''
    //   });
    // },
    // //删除
    // delLe2(index) {
    //   this.form.finRecords.splice(index, 1);
    // },
    //弹窗更新
    updateVisible(val) {
      this.dialogFormVisible = val;
    },
    openDioalog(formParm, msg) {
      console.log(formParm);
      this.dialogFormVisibleMsg = msg;
      this.dialogFormVisible = true;
      // let form = Object.assign({}, formParm);
      // console.log(form);
      // 重置
      this.resetForm('form');
      // if (null === formParm) {
      //   //新增
      //   // 重置
      //   // this.resetForm('form');
      // } else {
        //修改
        // 重置
        // this.resetForm('form');
        let form = Object.assign({}, formParm);
        this.form = Object.assign({}, form);
				this.form.oldUseAmt = formParm.useAmt;
				this.form.useAmt = 0;
				// this.form = {"id":form.id,"userId":form.userId,"useAmt":0};
        // this.getDetListData(form.opNo);
      // }
    },
    //打开编辑
    openEdit(event,row){
      event.stopPropagation();
      this.openDioalog(row, this.$t('6267f3aedf895209'))
    },
		//充值
		// payAction(event,row){
  //     event.stopPropagation();
  //     // this.openDioalog(row, this.$t('6267f3aedf895209'))
		// 	this.$confirm('确定该账户已充值 '+row.useAmt+' 吗？', this.$t('tips.tipsTitle'), {
		// 		// confirmButtonText: this.$t('204ffab8a6e01870'),
		// 		// cancelButtonText: this.$t('4b3340f7872b411f'),
		// 		type: 'warning'
		// 	})
		// 		.then(() => {
		// 			this.postData(this.$urlConfig.WhVerifiedRecharge+'/'+row.id, {}, () => {
		// 			  // this.dialogFormVisible = false;
		// 			  this.getPageData();
		// 			  // this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
		// 			  //   type: 'success',
		// 			  //   
		// 			  // });
		// 			  this.$message.success('确定充值成功！');
		// 			});
		// 		})
		// 		.catch(() => {
					
		// 		});
  //   },

    //删除，分成模板
    // deleteTem() {
    //   if (this.currentSel.confId == null) {
    //     this.$alert('请选择需要删除的数据!', this.$t('cc62f4bf31d661e3'));
    //     return;
    //   }

    //   // this.invalidDateForm.confId = this.currentSel.confId;
    //   // let formData = Object.assign({}, this.invalidDateForm);
    //   // // formData.id = this.currentSel.id;
    //   // this.postData(this.$urlConfig.HySettleConfAllocationOff, formData);
    // },

    // openDioalogInvalidDate() {
    //   console.log(this.currentSel);
    //   if (this.currentSel.confId == null) {
    //     this.$alert('请选择需要注销的数据!', this.$t('cc62f4bf31d661e3'));
    //     return;
    //   }
    //   this.dialogFormInvalidDate = true;
    //   let form = Object.assign({}, this.currentSel);
    //   // console.log(form);

    //   // 重置
    //   this.resetForm('invalidDateForm');
    //   this.invalidDateForm.confId = form.confId;
    //   this.invalidDateForm.confName = form.confName;
    //   this.invalidDateForm.effectiveDate = form.effectiveDate;
    //   // if(!!form.invalidDate){
    //   //   this.invalidDateForm.invalidDate = form.invalidDate;
    //   // } else {
    //   //   this.invalidDateForm.invalidDate = "";
    //   // }
    // },
    resetForm(formName) {
      console.log(formName);
      this[formName] = {};

      // if ('form' === formName) {
      //   this[formName]['finRecords'] = [];
      // }
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields();
        // this.form.remark = ""; //  备注//非必填
      } else {
        console.log('this.$refs[formName]', this.$refs[formName]);
      }
    },
    //编辑时，格式化list
    // fomatterConfigDetList(list) {
    //   list.forEach(function(item, index, array) {
    //     item.id = null;
    //   });
    //   return list;
    // },
    //提交信息
    submitForm(formName, type) {
      //验证省市县必输入
      this.$refs[formName].validate(valid => {
        // if (true) {
        if (valid) {
          // alert('submit!');

          //浅拷贝、对象属性的合并
          // this.form = Object.assign({},form);
          // if ('1' === type) {
            //新增
            let formData = Object.assign({}, this.form);
            // formData.id = null;
            this.postData(this.$urlConfig.WhAccountRecharge, formData, () => {
              this.dialogFormVisible = false;
              this.getPageData();
              // this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
              //   type: 'success',
              //   
              // });
              this.$message.success(this.$t('i18nn_994cb374e601fab3'));
            });
          // } 
					// else if ('2' === type) {
     //        //注销
     //        // let formData = Object.assign({}, this.invalidDateForm);
     //        // formData.id = this.currentSel.id;
     //        // this.postData(this.$urlConfig.HySettleConfAllocationOff, formData);
     //      } else if ('3' === type) {
     //        //编辑
     //        let formData = Object.assign({}, this.form);
     //        // formData.id = null;
     //        this.postData(this.$urlConfig.WhFinanceCreate, formData, () => {
     //          this.dialogFormVisible = false;
     //          this.getPageData();
     //          // this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
     //          //   type: 'success',
     //          //   
     //          // });
     //          this.$message.success('编辑成功！');
     //        });
     //      }
        } else {
          console.log('error submit!!');
          this.$alert(this.$t('hytxs0000031'), this.$t('cc62f4bf31d661e3'), {
            type: 'warning',
            
          });
          return false;
        }
      });
    },
    // getDicData() {
    //   this.$http
    //     .get(this.$urlConfig.HyMatchintFeeConfQueryDic)
    //     .then(({ data }) => {
    //       console.log(data);
    //       if (200 == data.code) {
    //         this.selectOption = data.data;
    //       } else {
    //         console.log('下拉列表数据请求失败!');
    //       }
    //       this.loading_load = false;
    //     })
    //     .catch(error => {
    //       console.log(error);
    //       console.log('更新特定数据，请求失败');
    //       this.loading_load = false;
    //     });
    // },
    // handleCurrentChange(val) {
    //   console.log('handleCurrentChange', val);
    //   // this.tableData.map(item => {
    //   //   if (item.confId === val.confId) {
    //   //     item.selected = true;
    //   //   } else {
    //   //     item.selected = false;
    //   //   }
    //   // });
    //   // this.currentSel = val;
    //   // this.$refs.multipleTable.toggleRowSelection(this.tableData3[2],true);
    // },
    //选择行
    handleCurrentChange(row, event, column) {
      // this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
    },
    //多选
    handleSelectionChange(val) {
      console.log(val);
      // this.multipleSelection = val;
    },
    //删除
        // delAction(event, row) {
        //         this.$confirm('确定删除吗?', this.$t('daaaeb1b7b22b126'), {
        //           // 
        //           // 
        //           type: 'warning'
        //         }).then(() => {
        //           // this.$message({
        //           //   type: 'success',
        //           //   message: '删除成功!'
        //           // });
        //           this.delDataAction(event, row);
        //         }).catch(() => {
        //           // this.$message({
        //           //   type: 'info',
        //           //   message: this.$t('i18nn_2e58cb9b52e2a214')
        //           // });
        //         });
        //       },
    //删除
    // delDataAction(event, row) {
    //   event.stopPropagation();
    //   console.log('delAction', row);
    //   let parm = [];
    //   parm = {};
    //   this.postData(this.$urlConfig.WhFinanceDel + '/' + row.id, parm, () => {
    //     this.initData();
    //     //  this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
    //     //   type: 'success',
    //     //   
    //     // });
    //     this.$message.success(this.$t('9f30371831a98237'));
    //   });
    // },
    //完成
    // completeAction(event,row) {
    //   event.stopPropagation();
    //   console.log('completeAction', row);
    //   let parm = [];

    //   if (row) {
    //     // 单条
    //     parm = [row.id];
    //   } else {
    //     //多条
    //     let dataList = this.multipleSelection;
    //     if (dataList.length < 1) {
    //       this.$message.warning(this.$t('7b80e66b535a3732'));
    //       return;
    //     }

    //     let dataListParm = dataList.map(v => v.id);
    //     parm = dataListParm;
    //     // console.log('dataListParm', dataListParm);
    //     // let dataParm = {
    //     //   ids: dataListParm
    //     // };
    //   }

    //   this.postData(this.$urlConfig.WhFinanceComplete, parm, () => {
    //     this.initData();
    //     // this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
    //     //   type: 'success',
    //     //   
    //     // });
    //     this.$message.success(this.$t('9f30371831a98237'));
    //     // if(row.id){
    //     //   row.dataFlag = '';
    //     //   this.$alert('恭喜,编辑成功！', this.$t('cc62f4bf31d661e3'), {
    //     //     type: 'success',
    //     //     
    //     //   });
    //     // } else {
    //     //   this.initData();
    //     //   this.$alert('恭喜,新增成功！', this.$t('cc62f4bf31d661e3'), {
    //     //     type: 'success',
    //     //     
    //     //   });
    //     // }
    //   });
    // },
    //开始拣货
    // startAction(event, row) {
    //   event.stopPropagation();
    //   console.log('startAction', row);
    //   let parm = [];
    //   parm = { plNo: row.plNo };
    //   this.postData(this.$urlConfig.WhPlStart, parm, () => {
    //     this.initData();
    //     //  this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
    //     //   type: 'success',
    //     //   
    //     // });
    //     this.$message.success(this.$t('9f30371831a98237'));
    //   });
    // },

    //提交数据
    postData(url, formData, callback) {
      // let _this = this;
      this.loading = true;

      this.$http
        .put(url, formData)
        .then(({ data }) => {
          console.log(this.$t('i18nn_bc868e024b80d2e3'));
          console.log(data);
          
          this.loading = false;
          if (200 == data.code) {
            callback();
          } else {
            if (!data.msg) {
              data.msg = this.$t('dbe331ab679cd67f');
            }
            this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
              type: 'warning',
              
            });
          }
        })
        .catch(error => {
          console.log(error);
          console.log(this.$t('i18nn_a7d2e953195a5588'));
          this.loading = false;
          this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
            type: 'warning',
            
          });
        });
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
// /deep/ .el-table__body {
//   .el-input {
//     input {
//       padding-left: 5px;
//       padding-right: 5px;
//     }
//   }
//   // .el-input-number {
//   //   width: 80px;
//   //   .el-input__inner {
//   //     text-align: left;
//   //   }
//   // }
// }
</style>
